<div>
	<div fxLayout="column" class="top-message">
		<div><marquee behavior="scroll" direction="left">DO NOT USE THE ACCOUNTING ZONE! PEOPLE GO THERE AND NEVER RETURN! - System Administrator</marquee></div>
	</div>

	<mat-toolbar color="primary" class="flex-p-x" class="top-bar">
		<div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
			<div fxLayout="row" fxLayoutAlign="end center">
				<div fxLayout="row" fxLayoutAlign="start center" fxShow="false" fxShow.gt-xs>
					<img src="/assets/images/logo-vergent.png" alt="Vergent LMS" class="logo" /> - Daily - 28293 - Main_20190425.14_7732
				</div>
			</div>
		</div>
	</mat-toolbar>
	<app-top-menu></app-top-menu>
</div>

<mat-sidenav-container>
	<mat-sidenav *ngIf="settings.menu == 'vertical'" [opened]="settings.sidenavIsOpened" [mode]="(settings.sidenavIsPinned) ? 'side' : 'over'" #sidenav class="sidenav mat-elevation-z6">
<!--		<app-sidenav></app-sidenav> -->
	</mat-sidenav>
<!--	<mat-sidenav-content perfectScrollbar [disabled]="settings.fixedHeader" (psScrollY)="onPsScrollY($event)"> -->
	<mat-sidenav-content (psScrollY)="onPsScrollY($event)">
		<mat-toolbar color="primary" class="flex-p-x">
			<div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
				<div fxLayout="row" fxLayoutAlign="center center">
					<button *ngIf="settings.menu == 'vertical'" mat-icon-button (click)="toggleSidenav()">
						<mat-icon>menu</mat-icon>
					</button> 
					<a *ngIf="settings.menu == 'horizontal'" mat-raised-button color="accent" routerLink="/" (click)="closeSubMenus()" class="small-logo">M</a>
					<a *ngIf="settings.menu == 'horizontal'" class="logo" routerLink="/" (click)="closeSubMenus()">AssetM</a>
				</div>
				<div fxLayout="row" fxLayoutAlign="end center">
					<div fxLayout="row" fxLayoutAlign="start center" fxShow="false" fxShow.gt-xs class="search-bar">
						<form method="get">
							<input type="text" placeholder="Type to search..." class="mat-elevation-z3"  [class.show]="toggleSearchBar">
							<button mat-icon-button (click)="toggleSearchBar = !toggleSearchBar" type="button">
								<mat-icon>search</mat-icon>
							</button> 
						</form>
					</div>
				</div>
			</div>
		</mat-toolbar>

		<div id="main-content" class="inner-sidenav-content transition-2" perfectScrollbar (psScrollY)="onPsScrollY($event)" [class.horizontal-menu-hidden]="isStickyMenu"> 
<!--		<div id="main-content" class="inner-sidenav-content transition-2" perfectScrollbar [disabled]="!settings.fixedHeader" (psScrollY)="onPsScrollY($event)" [class.horizontal-menu-hidden]="isStickyMenu"> 
			<app-breadcrumb></app-breadcrumb> -->
			<router-outlet></router-outlet>
		</div>

		<div *ngIf="showBackToTop" fxLayout="row" fxLayoutAlign="center center" class="back-to-top transition-2" (click)="scrollToTop();">
			<mat-icon>arrow_upward</mat-icon>
		</div>
	</mat-sidenav-content>
</mat-sidenav-container>
