import { Injectable } from '@angular/core';

// models
import { AuthToken } from '../../components/auth/auth-token.model';
import { TitleLogRole } from './enum-titlelog-role';
import { RepoLogRole } from 'app/repolog/enum/enum-repolog-role';

@Injectable
(
	{
		providedIn: 'root'
	}
)
export class SessionStorageHelper
{
	// Image Drag and Drop
	public imageArry(): Uint8Array
	{
		const sessionStorageImageArry = sessionStorage.getItem('imageArry');
		let imageArry = null;

		if (sessionStorageImageArry === undefined || sessionStorageImageArry === null) {
			imageArry = '';
		}
		else {
			imageArry = sessionStorageImageArry;
		}

		return imageArry;
	}

	// Image Drag/Drop - GET
	imageArryGet(): Uint8Array
	{
		const uint8Array = new TextEncoder().encode(sessionStorage.getItem('imageArray'));
		return uint8Array;
	}

	// Image Drag/Drop - SET
	imageArrySet(value: any)
	{
		sessionStorage.setItem('imageArray', new TextDecoder().decode(value));
	}

	// Authentication
	public authTokenGet(): string
	{
		const sessionStorageAuthToken = sessionStorage.getItem('auth_token');
		let authToken = '';

		if (sessionStorageAuthToken === undefined || sessionStorageAuthToken === null) {
			authToken = '';
		}
		else {
			authToken = sessionStorageAuthToken;
		}

		return authToken;
	}

	public authTokenRemove()
	{
		sessionStorage.removeItem('auth_token');
	}

	public expirationGet(): number
	{
		const sessionStorageExpiration = sessionStorage.getItem('expires_in');
		let expiration = 0;

		if (sessionStorageExpiration === undefined || sessionStorageExpiration === null) {
			expiration = 0;
		}
		else {
			expiration = new Date(sessionStorageExpiration).valueOf();
		}

		return expiration;
	}

	public authTokenSet(authToken: AuthToken)
	{
		sessionStorage.setItem('auth_token', authToken.auth_token);
		sessionStorage.setItem('userId', authToken.id);
		sessionStorage.setItem('companyId', authToken.companyId);
		sessionStorage.setItem('expires_in', authToken.expires_in);
		sessionStorage.setItem('api_token', authToken.api_token);
	}

	public apiTokenGet(): string
	{
		return sessionStorage.getItem('api_token');
	}

	public refererSet(value: string)
	{
		sessionStorage.setItem('referer', value);
	}

	public refererGet(): string
	{
		return sessionStorage.getItem('referer');
	}

	public titleId(): string
	{
		const sessionStorageTitleId = sessionStorage.getItem('titleId');
		let titleId = '';

		if (sessionStorageTitleId === undefined || sessionStorageTitleId === null) {
			titleId = '';
		}
		else {
			titleId = sessionStorageTitleId;
		}

		return titleId;
	}

	public userId(): string
	{
		const sessionStorageUserId = sessionStorage.getItem('userId');
		let userId = '';

		if (sessionStorageUserId === undefined || sessionStorageUserId === null) {
			userId = '';
		}
		else {
			userId = sessionStorageUserId;
		}

		return userId;
	}

	public companyId(): string
	{
		const sessionStorageCompanyId = sessionStorage.getItem('companyId');
		let companyId = '';

		if (sessionStorageCompanyId === undefined || sessionStorageCompanyId === null) {
			companyId = '';
		}
		else {
			companyId = sessionStorageCompanyId;
		}

		return companyId;
	}

	public storeId(): string
	{
		const sessionStorageStoreId = sessionStorage.getItem('storeId');
		let storeId = '0';

		if (sessionStorageStoreId === undefined || sessionStorageStoreId === null) {
			storeId = '0';
		}
		else {
			storeId = sessionStorageStoreId;
		}

		return storeId;
	}

	repoLogRoleSet(value: RepoLogRole)
	{
		sessionStorage.setItem('repoLogRole', value.toString());
	}

	repoLogRoleGet(): string
	{
		const role = sessionStorage.getItem('repoLogRole');
		return role;
	}

	// RepoLog - Get
	// RepoLog Approval Which queue to work with
	repoLogQueueTypeGet(): number
	{
		const repoLogQueueType = Number(sessionStorage.getItem('repoLogQueueType'));
		return repoLogQueueType;
	}

	// RepoLog Actionable Queue - GET
	repoLogQueueActionableCurrentPageGet(): string
	{
		const repoLogQueueActionableCurrentPage = sessionStorage.getItem('repoLogQueueActionableCurrentPage');
		return repoLogQueueActionableCurrentPage;
	}

	repoLogQueueActionableSortColumnGet(): string
	{
		const repoLogQueueActionableSortColumn = sessionStorage.getItem('repoLogQueueActionableSortColumn');
		return repoLogQueueActionableSortColumn;
	}

	repoLogQueueActionableSortOrderGet(): string
	{
		const repoLogQueueActionableSortOrder = sessionStorage.getItem('repoLogQueueActionableSortOrder');
		return repoLogQueueActionableSortOrder;
	}

	// RepoLog Search Queue - GET
	repoLogQueueSearchCurrentPageGet(): string
	{
		const repoLogQueueSearchCurrentPage = sessionStorage.getItem('repoLogQueueSearchCurrentPage');
		return repoLogQueueSearchCurrentPage;
	}

	repoLogQueueSearchSortColumnGet(): string
	{
		const repoLogQueueSearchSortColumn = sessionStorage.getItem('repoLogQueueSearchSortColumn');
		return repoLogQueueSearchSortColumn;
	}

	repoLogQueueSearchSortOrderGet(): string
	{
		const repoLogQueueSearchSortOrder = sessionStorage.getItem('repoLogQueueSearchSortOrder');
		return repoLogQueueSearchSortOrder;
	}

	repoLogQueueSearchTermGet(): string
	{
		const repoLogQueueSearchTerm = sessionStorage.getItem('repoLogQueueSearchTerm');
		return repoLogQueueSearchTerm;
	}

	// RepoLog - Set
	// RepoLog Queue Type - SET
	repoLogQueueTypeSet(value: string)
	{
		sessionStorage.setItem('repoLogQueueType', value);
	}

	// RepoLog Queue Actionable Queue - SET
	repoLogQueueActionableCurrentPageSet(value: string)
	{
		sessionStorage.setItem('repoLogQueueActionableCurrentPage', value);
	}

	repoLogQueueActionableSortColumnSet(value: string)
	{
		sessionStorage.setItem('repoLogQueueActionableSortColumn', value);
	}

	repoLogQueueActionableSortOrderSet(value: string)
	{
		sessionStorage.setItem('repoLogQueueActionableSortOrder', value);
	}

	// RepoLog Queue Search Queue - SET
	repoLogQueueSearchCurrentPageSet(value: string)
	{
		sessionStorage.setItem('repoLogQueueSearchCurrentPage', value);
	}

	repoLogQueueSearchSortColumnSet(value: string)
	{
		sessionStorage.setItem('repoLogQueueSearchSortColumn', value);
	}

	repoLogQueueSearchSortOrderSet(value: string)
	{
		sessionStorage.setItem('repoLogQueueSearchSortOrder', value);
	}

	repoLogQueueSearchTermSet(value: string)
	{
		sessionStorage.setItem('repoLogQueueSearchTerm', value);
	}

	repoLogQueueIsGridView(value: string)
	{
		sessionStorage.setItem('repoLogQueueIsGridView', value);
	}

	// RepoLog - Remove
	repoLogQueueTypeRemove()
	{
		sessionStorage.removeItem('repoLogQueueType');
	}

	repoLogQueueIdRemove()
	{
		sessionStorage.removeItem('repoLogQueueId');
	}

	repoLogQueueSearchTermRemove()
	{
		sessionStorage.removeItem('repoLogQueueSearchTerm');
	}

	//////////////////// Title Log //////////////////////
	////////// Desk User Get Next Review For Close Stuff /////////////////
	titleLogQueueReviewForCloseSearchTermGet(): string
	{
		const titleLogQueueReviewForCloseSearchTerm = sessionStorage.getItem('titleLogQueueReviewForCloseSearchTerm');
		return titleLogQueueReviewForCloseSearchTerm;
	}

	titleLogQueueReviewForCloseSearchTermSet(value: string)
	{
		sessionStorage.setItem('titleLogQueueReviewForCloseSearchTerm', value);
	}

	titleLogQueueReviewForCloseSearchTermRemove()
	{
		sessionStorage.removeItem('titleLogQueueReviewForCloseSearchTerm');
	}

	titleLogQueueReviewForCloseCurrentPageGet(): string
	{
		const titleLogQueueReviewForCloseCurrentPage = sessionStorage.getItem('titleLogQueueReviewForCloseCurrentPage');
		return titleLogQueueReviewForCloseCurrentPage;
	}

	titleLogQueueReviewForCloseCurrentPageSet(value: string)
	{
		sessionStorage.setItem('titleLogQueueReviewForCloseCurrentPage', value);
	}

	titleLogQueueReviewForCloseCurrentPageRemove()
	{
		sessionStorage.removeItem('titleLogQueueReviewForCloseCurrentPage');
	}

	titleLogQueueReviewForCloseItemsPerPageGet(): string
	{
		const titleLogQueueReviewForCloseItemsPerPage = sessionStorage.getItem('titleLogQueueReviewForCloseItemsPerPage');
		return titleLogQueueReviewForCloseItemsPerPage;
	}

	titleLogQueueReviewForCloseItemsPerPageSet(value: string)
	{
		sessionStorage.setItem('titleLogQueueReviewForCloseItemsPerPage', value);
	}

	titleLogQueueReviewForCloseItemsPerPageRemove()
	{
		sessionStorage.removeItem('titleLogQueueReviewForCloseItemsPerPage');
	}

	titleLogQueueReviewForCloseSortColumnGet(): string
	{
		const titleLogQueueReviewForCloseSortColumn = sessionStorage.getItem('titleLogQueueReviewForCloseSortColumn');
		return titleLogQueueReviewForCloseSortColumn;
	}

	titleLogQueueReviewForCloseSortColumnSet(value: string)
	{
		sessionStorage.setItem('titleLogQueueReviewForCloseSortColumn', value);
	}

	titleLogQueueReviewForCloseSortColumnRemove()
	{
		sessionStorage.removeItem('titleLogQueueReviewForCloseSortColumn');
	}

	titleLogQueueReviewForCloseSortOrderGet(): string
	{
		const titleLogQueueReviewForCloseSortOrder = sessionStorage.getItem('titleLogQueueReviewForCloseSortOrder');
		return titleLogQueueReviewForCloseSortOrder;
	}

	titleLogQueueReviewForCloseSortOrderSet(value: string)
	{
		sessionStorage.setItem('titleLogQueueReviewForCloseSortOrder', value);
	}

	titleLogQueueReviewForCloseSortOrderRemove()
	{
		sessionStorage.removeItem('titleLogQueueReviewForCloseSortOrder');
	}

	// TitleLog Get
	titleLogQueueIdGet(): string
	{
		const titleLogQueueId = sessionStorage.getItem('titleLogQueueId');
		return titleLogQueueId;
	}

	// TitleLog Get
	titleLogQueuePathGet(): string
	{
		const titleLogQueuePath = sessionStorage.getItem('titleLogQueuePath');
		return titleLogQueuePath;
	}

	titleLogQueueTypeGet(): number
	{
		const titleLogQueueType = Number(sessionStorage.getItem('titleLogQueueType'));
		return titleLogQueueType;
	}

	titleLogQueueIsGridViewGet(): boolean
	{
		const isGridView = Boolean(sessionStorage.getItem('titleLogQueueIsGridView'));
		return isGridView;
	}

	// TitleLog Ready For Review Queue - GET
	titleLogQueueReadyForReviewCurrentPageGet(): string
	{
		const titleLogQueueReadyForReviewCurrentPage = sessionStorage.getItem('titleLogQueueReadyForReviewCurrentPage');
		return titleLogQueueReadyForReviewCurrentPage;
	}

	titleLogQueueReadyForReviewSortColumnGet(): string
	{
		const titleLogQueueReadyForReviewSortColumn = sessionStorage.getItem('titleLogQueueReadyForReviewSortColumn');
		return titleLogQueueReadyForReviewSortColumn;
	}

	titleLogQueueReadyForReviewSortOrderGet(): string
	{
		const titleLogQueueReadyForReviewSortOrder = sessionStorage.getItem('titleLogQueueReadyForReviewSortOrder');
		return titleLogQueueReadyForReviewSortOrder;
	}

	// TitleLog Detail QueueId - SET
	titleLogQueueIdSet(value: string)
	{
		sessionStorage.setItem('titleLogQueueId', value);
	}

	// TitleLog Queue Path - SET
	titleLogQueuePathSet(value: string)
	{
		sessionStorage.setItem('titleLogQueuePath', value);
	}

	titleLogRoleSet(value: TitleLogRole)
	{
		sessionStorage.setItem('titleLogRole', value.toString());
	}

	titleLogRoleGet(): string
	{
		const role = sessionStorage.getItem('titleLogRole');
		return role;
	}

	// TitleLog Queue IsGridView - SET
	titleLogQueueIsGridView(value: string)
	{
		sessionStorage.setItem('titleLogQueueIsGridView', value);
	}

	titleLogSideNavExpanded(value: boolean)
	{
		sessionStorage.setItem('sidenavmenuexpanded', JSON.stringify(value));
	}

	titleLogSideNavExpandedExists(): boolean
	{
		const strExpanded = sessionStorage.getItem('sidenavmenuexpanded');
		const returnValue: boolean = ! (strExpanded === undefined || strExpanded === null);

		return returnValue;
	}

	titleLogSideNavExpandedGet(): boolean
	{
		const isExpanded = JSON.parse(sessionStorage.getItem('sidenavmenuexpanded'));
		return isExpanded;
	}

	/////////////////////// Sort Columns Problem/Hold Section
	titleLogQueueProblemHoldSortColumnSet(value: string)
	{
		sessionStorage.setItem('titleLogQueueProblemHoldSortColumn', value);
	}

	titleLogQueueProblemHoldSortOrderSet(value: string)
	{
		sessionStorage.setItem('titleLogQueueProblemHoldSortOrder', value);
	}

	titleLogQueueProblemHoldSearchTermSet(value: string)
	{
		sessionStorage.setItem('titleLogQueueProblemHoldSearchTerm', value);
	}

	titleLogQueueProblemHoldCurrentPageSet(value: string)
	{
		sessionStorage.setItem('titleLogQueueProblemHoldCurrentPage', value);
	}

	titleLogQueueProblemHoldSortColumnGet(): string
	{
		const titleLogQueueProblemHoldSortColumn = sessionStorage.getItem('titleLogQueueProblemHoldSortColumn');
		return titleLogQueueProblemHoldSortColumn;
	}

	titleLogQueueProblemHoldSortOrderGet(): string
	{
		const titleLogQueueProblemHoldSortOrder = sessionStorage.getItem('titleLogQueueProblemHoldSortOrder');
		return titleLogQueueProblemHoldSortOrder;
	}

	titleLogQueueProblemHoldSearchTermGet(): string
	{
		const titleLogQueueProblemHoldSearchTerm = sessionStorage.getItem('titleLogQueueProblemHoldSearchTerm');
		return titleLogQueueProblemHoldSearchTerm;
	}

	titleLogQueueProblemHoldCurrentPageGet(): string
	{
		const titleLogQueueProblemHoldCurrentPage = sessionStorage.getItem('titleLogQueueProblemHoldCurrentPage');
		return titleLogQueueProblemHoldCurrentPage;
	}

	/////////////////////// Sort Columns Inventory Section
	titleLogQueueInventorySortColumnSet(value: string)
	{
		sessionStorage.setItem('titleLogQueueInventorySortColumn', value);
	}

	titleLogQueueInventorySortOrderSet(value: string)
	{
		sessionStorage.setItem('titleLogQueueInventorySortOrder', value);
	}

	titleLogQueueInventorySearchTermSet(value: string)
	{
		sessionStorage.setItem('titleLogQueueInventorySearchTerm', value);
	}

	titleLogQueueInventoryCurrentPageSet(value: string)
	{
		sessionStorage.setItem('titleLogQueueInventoryCurrentPage', value);
	}

	titleLogQueueInventorySortColumnGet(): string
	{
		const titleLogQueueInventorySortColumn = sessionStorage.getItem('titleLogQueueInventorySortColumn');
		return titleLogQueueInventorySortColumn;
	}

	titleLogQueueInventorySortOrderGet(): string
	{
		const titleLogQueueInventorySortOrder = sessionStorage.getItem('titleLogQueueInventorySortOrder');
		return titleLogQueueInventorySortOrder;
	}

	titleLogQueueInventorySearchTermGet(): string
	{
		const titleLogQueueInventorySearchTerm = sessionStorage.getItem('titleLogQueueInventorySearchTerm');
		return titleLogQueueInventorySearchTerm;
	}

	titleLogQueueInventoryCurrentPageGet(): string
	{
		const titleLogQueueInventoryCurrentPage = sessionStorage.getItem('titleLogQueueInventoryCurrentPage');
		return titleLogQueueInventoryCurrentPage;
	}

	/////////////////////// Sort Columns Ready To Send To Dmv Section
	titleLogQueueReadyToSendToDmvSortColumnSet(value: string)
	{
		sessionStorage.setItem('titleLogQueueReadyToSendToDmvSortColumn', value);
	}

	titleLogQueueReadyToSendToDmvSortOrderSet(value: string)
	{
		sessionStorage.setItem('titleLogQueueReadyToSendToDmvSortOrder', value);
	}

	titleLogQueueReadyToSendToDmvSearchTermSet(value: string)
	{
		sessionStorage.setItem('titleLogQueueReadyToSendToDmvSearchTerm', value);
	}

	titleLogQueueReadyToSendToDmvCurrentPageSet(value: string)
	{
		sessionStorage.setItem('titleLogQueueReadyToSendToDmvCurrentPage', value);
	}

	titleLogQueueReadyToSendToDmvSortColumnGet(): string
	{
		const titleLogQueueReadyToSendToDmvSortColumn = sessionStorage.getItem('titleLogQueueReadyToSendToDmvSortColumn');
		return titleLogQueueReadyToSendToDmvSortColumn;
	}

	titleLogQueueReadyToSendToDmvSortOrderGet(): string
	{
		const titleLogQueueReadyToSendToDmvSortOrder = sessionStorage.getItem('titleLogQueueReadyToSendToDmvSortOrder');
		return titleLogQueueReadyToSendToDmvSortOrder;
	}

	titleLogQueueReadyToSendToDmvSearchTermGet(): string
	{
		const titleLogQueueReadyToSendToDmvSearchTerm = sessionStorage.getItem('titleLogQueueReadyToSendToDmvSearchTerm');
		return titleLogQueueReadyToSendToDmvSearchTerm;
	}

	titleLogQueueReadyToSendToDmvCurrentPageGet(): string
	{
		const titleLogQueueReadyToSendToDmvCurrentPage = sessionStorage.getItem('titleLogQueueReadyToSendToDmvCurrentPage');
		return titleLogQueueReadyToSendToDmvCurrentPage;
	}

	/////////////////////// Sort Columns VehicleProblemReview Section
	titleLogQueueVehicleProblemReviewSortColumnSet(value: string)
	{
		sessionStorage.setItem('titleLogQueueVehicleProblemReviewSortColumn', value);
	}

	titleLogQueueVehicleProblemReviewSortOrderSet(value: string)
	{
		sessionStorage.setItem('titleLogQueueVehicleProblemReviewSortOrder', value);
	}

	titleLogQueueVehicleProblemReviewSearchTermSet(value: string)
	{
		sessionStorage.setItem('titleLogQueueVehicleProblemReviewSearchTerm', value);
	}

	titleLogQueueVehicleProblemReviewCurrentPageSet(value: string)
	{
		sessionStorage.setItem('titleLogQueueVehicleProblemReviewCurrentPage', value);
	}

	titleLogQueueVehicleProblemReviewItemsPerPageSet(value: string)
	{
		sessionStorage.setItem('titleLogQueueVehicleProblemReviewItemsPerPage', value);
	}

	titleLogQueueVehicleProblemReviewSortColumnGet(): string
	{
		const titleLogQueueVehicleProblemReviewSortColumn = sessionStorage.getItem('titleLogQueueVehicleProblemReviewSortColumn');
		return titleLogQueueVehicleProblemReviewSortColumn;
	}

	titleLogQueueVehicleProblemReviewSortOrderGet(): string
	{
		const titleLogQueueVehicleProblemReviewSortOrder = sessionStorage.getItem('titleLogQueueVehicleProblemReviewSortOrder');
		return titleLogQueueVehicleProblemReviewSortOrder;
	}

	titleLogQueueVehicleProblemReviewSearchTermGet(): string
	{
		const titleLogQueueVehicleProblemReviewSearchTerm = sessionStorage.getItem('titleLogQueueVehicleProblemReviewSearchTerm');
		return titleLogQueueVehicleProblemReviewSearchTerm;
	}

	titleLogQueueVehicleProblemReviewCurrentPageGet(): string
	{
		const titleLogQueueVehicleProblemReviewCurrentPage = sessionStorage.getItem('titleLogQueueVehicleProblemReviewCurrentPage');
		return titleLogQueueVehicleProblemReviewCurrentPage;
	}

	titleLogQueueVehicleProblemReviewItemsPerPageGet(): string
	{
		const titleLogQueueVehicleProblemReviewItemsPerPage = sessionStorage.getItem('titleLogQueueVehicleProblemReviewItemsPerPage');
		return titleLogQueueVehicleProblemReviewItemsPerPage;
	}

	titleLogQueueVehicleProblemReviewItemsPerPageRemove()
	{
		sessionStorage.removeItem('titleLogQueueVehicleProblemReviewItemsPerPage');
	}

	/////////////////////// Sort Columns Sent To Dmv Section
	titleLogQueueSentToDmvSortColumnSet(value: string)
	{
		sessionStorage.setItem('titleLogQueueSentToDmvSortColumn', value);
	}

	titleLogQueueSentToDmvSortOrderSet(value: string)
	{
		sessionStorage.setItem('titleLogQueueSentToDmvSortOrder', value);
	}

	titleLogQueueSentToDmvSearchTermSet(value: string)
	{
		sessionStorage.setItem('titleLogQueueSentToDmvSearchTerm', value);
	}

	titleLogQueueSentToDmvCurrentPageSet(value: string)
	{
		sessionStorage.setItem('titleLogQueueSentToDmvCurrentPage', value);
	}

	titleLogQueueSentToDmvSortColumnGet(): string
	{
		const titleLogQueueSentToDmvSortColumn = sessionStorage.getItem('titleLogQueueSentToDmvSortColumn');
		return titleLogQueueSentToDmvSortColumn;
	}

	titleLogQueueSentToDmvSortOrderGet(): string
	{
		const titleLogQueueSentToDmvSortOrder = sessionStorage.getItem('titleLogQueueSentToDmvSortOrder');
		return titleLogQueueSentToDmvSortOrder;
	}

	titleLogQueueSentToDmvSearchTermGet(): string
	{
		const titleLogQueueSentToDmvSearchTerm = sessionStorage.getItem('titleLogQueueSentToDmvSearchTerm');
		return titleLogQueueSentToDmvSearchTerm;
	}

	titleLogQueueSentToDmvCurrentPageGet(): string
	{
		const titleLogQueueSentToDmvCurrentPage = sessionStorage.getItem('titleLogQueueSentToDmvCurrentPage');
		return titleLogQueueSentToDmvCurrentPage;
	}

	/////////////////////// Date Filter Section
	titleLogQueueStartDateRemove()
	{
		sessionStorage.removeItem('titleLogQueueStartDate');
	}

	titleLogQueueEndDateRemove()
	{
		sessionStorage.removeItem('titleLogQueueEndDate');
	}

	titleLogQueueStartDateGet(): string
	{
		const titleLogQueueStartDate = sessionStorage.getItem('titleLogQueueStartDate');
		return titleLogQueueStartDate;
	}

	titleLogQueueEndDateGet(): string
	{
		const titleLogQueueEndDate = sessionStorage.getItem('titleLogQueueEndDate');
		return titleLogQueueEndDate;
	}

	titleLogQueueStartDateSet(value: string)
	{
		sessionStorage.setItem('titleLogQueueStartDate', value);
	}

	titleLogQueueEndDateSet(value: string)
	{
		sessionStorage.setItem('titleLogQueueEndDate', value);
	}

	/////////////////////// Region Filter Section
	titleLogQueueRegionIdsGet(): number[]
	{
		const selectedRegionIds: number[] = [];

		const titleLogQueueRegionIds = sessionStorage.getItem('titleLogQueueRegionIds');
		const stringArray = titleLogQueueRegionIds !== null && titleLogQueueRegionIds !== '' ? titleLogQueueRegionIds.split(',') : [];

		stringArray.forEach(element => {
			selectedRegionIds.push(Number(element));
		});

		return selectedRegionIds;
	}

	titleLogQueueRegionIdsSet(value: string)
	{
		sessionStorage.setItem('titleLogQueueRegionIds', value);
	}

	titleLogQueueRegionIdsRemove()
	{
		sessionStorage.removeItem('titleLogQueueRegionIds');
	}

	/////////////////////// District Filter Section
	titleLogQueueDistrictIdsGet(): number[]
	{
		const selectedDistrictIds: number[] = [];

		const titleLogQueueDistrictIds = sessionStorage.getItem('titleLogQueueDistrictIds');
		const stringArray = titleLogQueueDistrictIds !== null && titleLogQueueDistrictIds !== '' ? titleLogQueueDistrictIds.split(',') : [];

		stringArray.forEach(element => {
			selectedDistrictIds.push(Number(element));
		});

		return selectedDistrictIds;
	}

	titleLogQueueDistrictIdsSet(value: string)
	{
		sessionStorage.setItem('titleLogQueueDistrictIds', value);
	}

	titleLogQueueDistrictIdsRemove()
	{
		sessionStorage.removeItem('titleLogQueueDistrictIds');
	}

	/////////////////////// Store Filter Section
	titleLogQueueStoreIdsGet(): number[]
	{
		const selectedStoreIds: number[] = [];
		const titleLogDeskUserQueueStoreIds = sessionStorage.getItem('titleLogQueueStoreIds');
		const stringArray = titleLogDeskUserQueueStoreIds !== null && titleLogDeskUserQueueStoreIds !== '' ? titleLogDeskUserQueueStoreIds.split(',') : [];

		stringArray.forEach(element => {
			selectedStoreIds.push(Number(element));
		});

		return selectedStoreIds;
	}

	titleLogQueueStoreIdsSet(value: string)
	{
		sessionStorage.setItem('titleLogQueueStoreIds', value);
	}

	titleLogQueueStoreIdsRemove()
	{
		sessionStorage.removeItem('titleLogQueueStoreIds');
	}

	/////////////////////// State Filter Section
	titleLogQueueStateIdRemove()
	{
		sessionStorage.removeItem('titleLogQueueStateId');
	}

	titleLogQueueStateRemove()
	{
		sessionStorage.removeItem('titleLogQueueState');
	}

	titleLogQueueStateIdGet(): number[]
	{
		const selectedStateIds: number[] = [];
		const titleLogQueueStateId = sessionStorage.getItem('titleLogQueueStateId');
		const stringArray = titleLogQueueStateId !== null && titleLogQueueStateId !== '' ? titleLogQueueStateId.split(',') : [];

		stringArray.forEach(element => {
			selectedStateIds.push(Number(element));
		});

		return selectedStateIds;
	}

	titleLogQueueStateIdSet(value: string)
	{
		sessionStorage.setItem('titleLogQueueStateId', value);
	}

	/////////////////////// Manager Date Filter Section
	titleLogManagerQueueStartDateRemove()
	{
		sessionStorage.removeItem('titleLogManagerQueueStartDate');
	}

	titleLogManagerQueueEndDateRemove()
	{
		sessionStorage.removeItem('titleLogManagerQueueEndDate');
	}

	titleLogManagerQueueStartDateGet(): string
	{
		const titleLogManagerQueueStartDate = sessionStorage.getItem('titleLogManagerQueueStartDate');
		return titleLogManagerQueueStartDate;
	}

	titleLogManagerQueueEndDateGet(): string
	{
		const titleLogManagerQueueEndDate = sessionStorage.getItem('titleLogManagerQueueEndDate');
		return titleLogManagerQueueEndDate;
	}

	titleLogManagerQueueStartDateSet(value: string)
	{
		sessionStorage.setItem('titleLogManagerQueueStartDate', value);
	}

	titleLogManagerQueueEndDateSet(value: string)
	{
		sessionStorage.setItem('titleLogManagerQueueEndDate', value);
	}

	/////////////////////// END Manager Date Filter Section

	/////////////////////// Manager State Filter Section
	titleLogManagerQueueStateIdRemove()
	{
		sessionStorage.removeItem('titleLogManagerQueueStateId');
	}

	titleLogManagerQueueStateRemove()
	{
		sessionStorage.removeItem('titleLogManagerQueueState');
	}

	titleLogManagerQueueStateIdGet(): number[]
	{
		const selectedStateIds: number[] = [];

		const titleLogManagerQueueStateId = sessionStorage.getItem('titleLogManagerQueueStateId');
		const stringArray = titleLogManagerQueueStateId !== null && titleLogManagerQueueStateId !== '' ? titleLogManagerQueueStateId.split(',') : [];

		stringArray.forEach(element => {
			selectedStateIds.push(Number(element));
		});

		return selectedStateIds;
	}

	titleLogManagerQueueStateIdSet(value: string)
	{
		sessionStorage.setItem('titleLogManagerQueueStateId', value);
	}

	/////////////////////// END Manager State Filter Section

	/////////////////////// Manager Region Filter Section
	titleLogManagerQueueRegionIdsGet(): number[]
	{
		const selectedRegionIds: number[] = [];
		const titleLogManagerQueueRegionIds = sessionStorage.getItem('titleLogManagerQueueRegionIds');
		const stringArray = titleLogManagerQueueRegionIds !== null && titleLogManagerQueueRegionIds !== '' ? titleLogManagerQueueRegionIds.split(',') : [];

		stringArray.forEach(element => {
			selectedRegionIds.push(Number(element));
		});

		return selectedRegionIds;
	}

	titleLogManagerQueueRegionIdsSet(value: string)
	{
		sessionStorage.setItem('titleLogManagerQueueRegionIds', value);
	}

	titleLogManagerQueueRegionIdsRemove()
	{
		sessionStorage.removeItem('titleLogManagerQueueRegionIds');
	}

	/////////////////////// END Manager Region Filter Section

	/////////////////////// Manager District Filter Section
	titleLogManagerQueueDistrictIdsGet(): number[]
	{
		const selectedDistrictIds: number[] = [];
		const titleLogManagerQueueDistrictIds = sessionStorage.getItem('titleLogManagerQueueDistrictIds');
		const stringArray = titleLogManagerQueueDistrictIds !== null && titleLogManagerQueueDistrictIds !== '' ? titleLogManagerQueueDistrictIds.split(',') : [];

		stringArray.forEach(element => {
			selectedDistrictIds.push(Number(element));
		});

		return selectedDistrictIds;
	}

	titleLogManagerQueueDistrictIdsSet(value: string)
	{
		sessionStorage.setItem('titleLogManagerQueueDistrictIds', value);
	}

	titleLogManagerQueueDistrictIdsRemove()
	{
		sessionStorage.removeItem('titleLogManagerQueueDistrictIds');
	}

	/////////////////////// END Manager District Filter Section

	/////////////////////// Store Filter Section
	titleLogManagerQueueStoreIdsGet(): number[]
	{
		const selectedStoreIds: number[] = [];
		const titleLogManagerQueueStoreIds = sessionStorage.getItem('titleLogManagerQueueStoreIds');
		const stringArray = titleLogManagerQueueStoreIds !== null && titleLogManagerQueueStoreIds !== '' ? titleLogManagerQueueStoreIds.split(',') : [];

		stringArray.forEach(element => {
			selectedStoreIds.push(Number(element));
		});

		return selectedStoreIds;
	}

	titleLogManagerQueueStoreIdsSet(value: string)
	{
		sessionStorage.setItem('titleLogManagerQueueStoreIds', value);
	}

	titleLogManagerQueueStoreIdsRemove()
	{
		sessionStorage.removeItem('titleLogManagerQueueStoreIds');
	}

	/////////////////////// END Store Filter Section

	/////////////////////// Unable to Secure Section
	titleLogQueueUnableToSecureLienSortColumnSet(value: string)
	{
		sessionStorage.setItem('titleLogQueueUnableToSecureLienSortColumn', value);
	}

	titleLogQueueUnableToSecureLienSortOrderSet(value: string)
	{
		sessionStorage.setItem('titleLogQueueUnableToSecureLienSortOrder', value);
	}

	titleLogQueueUnableToSecureLienSearchTermSet(value: string)
	{
		sessionStorage.setItem('titleLogQueueUnableToSecureLienSearchTerm', value);
	}

	titleLogQueueUnableToSecureLienCurrentPageSet(value: string)
	{
		sessionStorage.setItem('titleLogQueueUnableToSecureLienCurrentPage', value);
	}

	titleLogQueueUnableToSecureLienSortColumnGet(): string
	{
		const titleLogQueueUnableToSecureLienSortColumn = sessionStorage.getItem('titleLogQueueUnableToSecureLienSortColumn');
		return titleLogQueueUnableToSecureLienSortColumn;
	}

	titleLogQueueUnableToSecureLienSortOrderGet(): string
	{
		const titleLogQueueUnableToSecureLienSortOrder = sessionStorage.getItem('titleLogQueueUnableToSecureLienSortOrder');
		return titleLogQueueUnableToSecureLienSortOrder;
	}

	titleLogQueueUnableToSecureLienSearchTermGet(): string
	{
		const titleLogQueueUnableToSecureLienSearchTerm = sessionStorage.getItem('titleLogQueueUnableToSecureLienSearchTerm');
		return titleLogQueueUnableToSecureLienSearchTerm;
	}

	titleLogQueueUnableToSecureLienCurrentPageGet(): string
	{
		const titleLogQueueUnableToSecureLienCurrentPage = sessionStorage.getItem('titleLogQueueUnableToSecureLienCurrentPage');
		return titleLogQueueUnableToSecureLienCurrentPage;
	}
	/////////////////////// END Unable to Secure Section
}
