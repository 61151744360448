import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http/http';

@Injectable
(
	{
		providedIn: 'root'
	}
)

export class HeaderHelper
{
	addApplicationJsonHeader(httpHeaders: HttpHeaders): HttpHeaders
	{
		httpHeaders = httpHeaders.append('Content-Type', 'application/json; charset=utf-8');
		httpHeaders = httpHeaders.append('Cache-Control', 'no-cache');
		httpHeaders = httpHeaders.append('Pragma', 'no-cache');

		return httpHeaders;
	}

	addMultipartFormHeader(httpHeaders: HttpHeaders): HttpHeaders
	{
		httpHeaders = httpHeaders.append('enctype', 'multipart/form-data');
		return httpHeaders;
	}
}
