<div fxLayout="column" class="top-menu">
	<div>
		<!-- <button mat-button [matMenuTriggerFor]="home">
			<span>Home</span>
			<mat-icon>arrow_drop_down</mat-icon>
		</button>
		<mat-menu #home="matMenu">
			<a href="{{ legacyVergentBaseUrl }}default.aspx"><button mat-menu-item>Overview</button></a>
			<a href="{{ legacyVergentBaseUrl }}leads.aspx"><button mat-menu-item>Leads</button></a>
			<a href="{{ legacyVergentBaseUrl }}leads/default.aspx"><button mat-menu-item>Leads Queue</button></a>
			<a href="{{ legacyVergentBaseUrl }}snapshot.aspx"><button mat-menu-item>Snapshot</button></a>
			<a href="{{ legacyVergentBaseUrl }}cc/corporate.aspx"><button mat-menu-item>Central Collections</button></a>
		</mat-menu>

		<button mat-button [matMenuTriggerFor]="customers">
			<span>Customers</span>
			<mat-icon>arrow_drop_down</mat-icon>
		</button>
		<mat-menu #customers="matMenu">
			<div fxLayout="row" fxLayoutGap="2px">
				<div fxLayout="column">
					<button mat-menu-item><b>Search</b></button>
					<mat-divider></mat-divider> <br/>

					<input matInput placeholder="Name" /><br/>
					<input matInput placeholder="Case #" /><br/>
					<input matInput placeholder="Phone #" /><br/>
					<input matInput placeholder="ID #" /><br/>
					<input matInput placeholder="Loan ID" /><br/>
					<input matInput placeholder="VIN" /><br/>
					<button (click)="redirectToSearch()">Search</button>
				</div>

				<div fxLayout="column">
					<button mat-menu-item><b>Recent</b></button>
					<mat-divider></mat-divider>
				</div>

				<div fxLayout="column">
					<button mat-menu-item></button>
					<mat-divider></mat-divider>
					<a href="{{ legacyVergentBaseUrl }}customer/add.aspx"><button mat-menu-item>Add Customer</button></a>
					<a href="{{ legacyVergentBaseUrl }}customer/default.aspx"><button mat-menu-item>Advanced Search</button></a>
				</div>
			</div>
		</mat-menu>

		<button mat-button [matMenuTriggerFor]="transactions">
			<span>Transactions</span>
			<mat-icon>arrow_drop_down</mat-icon>
		</button>
		<mat-menu #transactions="matMenu">
			<a href="{{ legacyVergentBaseUrl }}advance/default.aspx"><button mat-menu-item>Advance</button></a>
			<a href="{{ legacyVergentBaseUrl }}title/default.aspx"><button mat-menu-item>Title</button></a>
			<a href="{{ legacyVergentBaseUrl }}customer/default.aspx"><button mat-menu-item>Installment</button></a>
			<a href="{{ legacyVergentBaseUrl }}check/default.aspx"><button mat-menu-item>Check Cashing</button></a>
			<a href="{{ legacyVergentBaseUrl }}customer/default.aspx"><button mat-menu-item>Sales</button></a>
		</mat-menu>

		<button mat-button [matMenuTriggerFor]="timeclock">
			<span>Time Clock</span>
			<mat-icon>arrow_drop_down</mat-icon>
		</button>
		<mat-menu #timeclock="matMenu">
			<a href="{{ legacyVergentBaseUrl }}clock.aspx"><button mat-menu-item>Clock In / Out</button></a>
			<a href="{{ legacyVergentBaseUrl }}clock_report.aspx"><button mat-menu-item>Time Reports</button></a>
			<a href="{{ legacyVergentBaseUrl }}clock_mod.aspx"><button mat-menu-item>Modify Entry</button></a>
		</mat-menu>

		<button mat-button [matMenuTriggerFor]="reports">
			<span>Reports</span>
			<mat-icon>arrow_drop_down</mat-icon>
		</button>
		<mat-menu #reports="matMenu">
			<div fxLayout="row" fxLayoutGap="2px">
				<div fxLayout="column">
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=1"><button mat-menu-item><b>Advance</b></button></a>
					<mat-divider></mat-divider>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=1&report_id=1"><button mat-menu-item>Advance Report</button></a>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=1&report_id=4"><button mat-menu-item>Advances Due</button></a>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=1&report_id=2"><button mat-menu-item>Daily Activity</button></a>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=1&report_id=179"><button mat-menu-item>Inventroy Stack Report</button></a>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=1&report_id=15"><button mat-menu-item>New Returned Checks</button></a>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=1&report_id=13"><button mat-menu-item>Store Detail</button></a>
					<a href="{{ legacyVergentBaseUrl }}tools/reports.aspx?type_id=1&report_id=225"><button mat-menu-item>Store Detail (v3)</button></a>
				</div>

				<div fxLayout="column">
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=2"><button mat-menu-item><b>Check Cashing</b></button></a>
					<mat-divider></mat-divider>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=2&report_id=36"><button mat-menu-item>Check Cashing Report</button></a>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=2&report_id=109"><button mat-menu-item>Currency Transaction Report</button></a>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=2&report_id=39"><button mat-menu-item>Daily Activity</button></a>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=2&report_id=99"><button mat-menu-item>Store Detail</button></a>
				</div>

				<div fxLayout="column">
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=3"><button mat-menu-item><b>Title Loan</b></button></a>
					<mat-divider></mat-divider>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=3&report_id=26"><button mat-menu-item>Daily Activity</button></a>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=3&report_id=164"><button mat-menu-item>New Pawn</button></a>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=3&report_id=29"><button mat-menu-item>Pledge Log</button></a>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=3&report_id=28"><button mat-menu-item>Store Detail</button></a>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=3&report_id=25"><button mat-menu-item>Title Loan Report</button></a>
				</div>

				<div fxLayout="column">
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=12"><button mat-menu-item><b>LOC Loan &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </b></button></a>
					<mat-divider></mat-divider>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=12&report_id=223"><button mat-menu-item>Daily Activity</button></a>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=12&report_id=218"><button mat-menu-item>LOC Balance Sheet</button></a>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=12&report_id=228"><button mat-menu-item>LOC Loan Report</button></a>
				</div>

				<div fxLayout="column">
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=11"><button mat-menu-item><b>Installment Loan &nbsp; &nbsp; </b></button></a>
					<mat-divider></mat-divider>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=11&report_id=205"><button mat-menu-item>Daily Activity</button></a>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=11&report_id=212"><button mat-menu-item>Installment Loan Report</button></a>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=11&report_id=207"><button mat-menu-item>Pledge Log</button></a>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=11&report_id=206"><button mat-menu-item>Store Detail</button></a>
				</div>

				<div fxLayout="column">
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=4"><button mat-menu-item><b>Financial</b></button></a>
					<mat-divider></mat-divider>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=4&report_id=41"><button mat-menu-item>Bank Deposit and Withdraw</button></a>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=4&report_id=46"><button mat-menu-item>Check Disbursement</button></a>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=4&report_id=76"><button mat-menu-item>Daily Held Report</button></a>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=4&report_id=53"><button mat-menu-item>Manual Audit</button></a>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=4&report_id=178"><button mat-menu-item>Over/Short Report</button></a>
				</div>
			</div>

			<br/>
			<div fxLayout="row" fxLayoutGap="2px">
				<div fxLayout="column">
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=7"><button mat-menu-item><b>Common</b></button></a>
					<mat-divider></mat-divider>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=7&report_id=70"><button mat-menu-item>ACH Schedule</button></a>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=7&report_id=132"><button mat-menu-item>Contact Record</button></a>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=7&report_id=67"><button mat-menu-item>Daily Activity</button></a>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=7&report_id=108"><button mat-menu-item>Potential ACH</button></a>
				</div>

				<div fxLayout="column">
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=5"><button mat-menu-item><b>Customer</b></button></a>
					<mat-divider></mat-divider>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=5&report_id=90"><button mat-menu-item>Appointments</button></a>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=5&report_id=185"><button mat-menu-item>Customer Information</button></a>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=5&report_id=58"><button mat-menu-item>Customer Report</button></a>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=5&report_id=96"><button mat-menu-item>Due To Pay</button></a>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=5&report_id=60"><button mat-menu-item>Incomplete Customer</button></a>
				</div>

				<div fxLayout="column">
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=6"><button mat-menu-item><b>Employee</b></button></a>
					<mat-divider></mat-divider>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=6&report_id=66"><button mat-menu-item>Audit Log</button></a>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=6&report_id=12"><button mat-menu-item>Daily User Productivity</button></a>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=6&report_id=63"><button mat-menu-item>Employee Report</button></a>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=6&report_id=64"><button mat-menu-item>Time Clock</button></a>
				</div>

				<div fxLayout="column">
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=9"><button mat-menu-item><b>Employee Audit</b></button></a>
					<mat-divider></mat-divider>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=9&report_id=130"><button mat-menu-item>Employee Productivity Report</button></a>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=9&report_id=136"><button mat-menu-item>Employee Productivity Report (Title Loan)</button></a>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=9&report_id=131"><button mat-menu-item>Loan Increase Report</button></a>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=9&report_id=143"><button mat-menu-item>New Customer Report</button></a>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=9&report_id=21"><button mat-menu-item>Red Flag</button></a>
				</div>

				<div fxLayout="column">
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=10"><button mat-menu-item><b>Central Collections</b></button></a>
					<mat-divider></mat-divider>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=10&report_id=263"><button mat-menu-item>Agent Call Report</button></a>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=10&report_id=155"><button mat-menu-item>Collections Report</button></a>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=10&report_id=157"><button mat-menu-item>Customer Report</button></a>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=10&report_id=156"><button mat-menu-item>Loan Report</button></a>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=10&report_id=158"><button mat-menu-item>Master Payments Report</button></a>
				</div>

				<div fxLayout="column">
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=8"><button mat-menu-item><b>Internet Lending</b></button></a>
					<mat-divider></mat-divider>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=8&report_id=91"><button mat-menu-item>Lead Buying</button></a>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=8&report_id=93"><button mat-menu-item>Loan Requests</button></a>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=8&report_id=92"><button mat-menu-item>Organic Signups</button></a>
					<a href="{{ legacyVergentBaseUrl }}reports.aspx?type_id=8&report_id=98"><button mat-menu-item>Return on Investment</button></a>
				</div>
			</div>
		</mat-menu>

		<button mat-button [matMenuTriggerFor]="tools">
			<span>Tools</span>
			<mat-icon>arrow_drop_down</mat-icon>
		</button>
		<mat-menu #tools="matMenu">
			<div fxLayout="row" fxLayoutGap="2px">
				<div fxLayout="column">
					<a href="{{ legacyVergentBaseUrl }}setup/default.aspx"><button mat-menu-item><b>Transactions</b></button></a>
					<mat-divider></mat-divider>
					<a href="{{ legacyVergentBaseUrl }}tools/miscsales.aspx"><button mat-menu-item>Sales</button></a>
					<a href="{{ legacyVergentBaseUrl }}tools/pettycash.aspx"><button mat-menu-item>Petty Cash</button></a>
					<a href="{{ legacyVergentBaseUrl }}tools/batchcc.aspx"><button mat-menu-item>Batch CC Processing</button></a>
					<a href="{{ legacyVergentBaseUrl }}tools/drawer2.aspx?mode=5"><button mat-menu-item>Drawer Transfer</button></a>
					<a href="{{ legacyVergentBaseUrl }}tools/drawer2.aspx?mode=5"><button mat-menu-item>Drawer Transfer</button></a>
					<button mat-menu-item>Inter-Store Transfer</button>
					<a href="{{ legacyVergentBaseUrl }}tools/drawer_adjust.aspx?type=withdraw"><button mat-menu-item>Withdraw Cash</button></a>
					<a href="{{ legacyVergentBaseUrl }}tools/drawer_adjust.aspx?type=deposit"><button mat-menu-item>Deposit Cash</button></a>
					<a href="{{ legacyVergentBaseUrl }}tools/clear.aspx"><button mat-menu-item>Clear Other Items</button></a>
					<a href="{{ legacyVergentBaseUrl }}tools/chkcash_deposit.aspx"><button mat-menu-item>Deposit Cashed Checks</button></a>
					<a href="{{ legacyVergentBaseUrl }}tools/chkcash_clear.aspx"><button mat-menu-item>Clear Cashed Checks</button></a>
					<a href="{{ legacyVergentBaseUrl }}tools/adv_deposit.aspx"><button mat-menu-item>Deposit Adv Checks</button></a>
					<a href="{{ legacyVergentBaseUrl }}tools/clvr2.aspx"><button mat-menu-item>Clear Adv/Pmt Checks</button></a>
					<a href="{{ legacyVergentBaseUrl }}tools/rtnv2.aspx"><button mat-menu-item>Return Adv/Pmt Checks</button></a>
					<a href="{{ legacyVergentBaseUrl }}tools/default_chk.aspx"><button mat-menu-item>Default Loans</button></a>
					<button mat-menu-item>Trial Balance</button> 
					<button mat-menu-item>Close Day</button> 
					<a href="{{ legacyVergentBaseUrl }}tools/wupaymentexceptions.aspx"><button mat-menu-item>WU Payment Exceptions</button></a>
					<a href="{{ legacyVergentBaseUrl }}tools/trustee_payment_exceptions.aspx"><button mat-menu-item>Trustee Pmt Exceptions</button></a>
					<a href="{{ legacyVergentBaseUrl }}tools/right_to_cure.aspx"><button mat-menu-item>Right To Cure</button></a>
					<a href="{{ legacyVergentBaseUrl }}tools/oerp.aspx"><button mat-menu-item>OERP</button></a>
					<button mat-menu-item>Import Payments</button> 
				</div>

				<div fxLayout="column">
					<a href="{{ legacyVergentBaseUrl }}tools/ach.aspx"><button mat-menu-item><b>ACH Management</b></button></a>
					<mat-divider></mat-divider>
					<a href="{{ legacyVergentBaseUrl }}tools/pastdue_ach.aspx"><button mat-menu-item>Past Due to ACH</button></a>
					<a href="{{ legacyVergentBaseUrl }}tools/ach.aspx?a=unapproved"><button mat-menu-item>Unapproved</button></a>
					<a href="{{ legacyVergentBaseUrl }}tools/ach.aspx?a=approved"><button mat-menu-item>Approved</button></a>
					<a href="{{ legacyVergentBaseUrl }}tools/ach.aspx?a=sent"><button mat-menu-item>View Sent Items</button></a>
					<a href="{{ legacyVergentBaseUrl }}tools/ach.aspx?a=rtnclr"><button mat-menu-item>Clear or Return Items</button></a>
					<a href="{{ legacyVergentBaseUrl }}tools/ach.aspx?a=return"><button mat-menu-item>Check Returns</button></a>
					<a href="{{ legacyVergentBaseUrl }}tools/ach.aspx?a=check"><button mat-menu-item>Check Schedule</button></a>
				</div>

				<div fxLayout="column">
					<button mat-menu-item><b>Other</b></button>
					<mat-divider></mat-divider>
					<a href="{{ legacyVergentBaseUrl }}tools/store_docs.aspx"><button mat-menu-item>Store Documents</button></a>
					<a href="{{ legacyVergentBaseUrl }}setup/inventoryreceive.aspx"><button mat-menu-item>Receive Inventory</button></a>
					<a href="{{ legacyVergentBaseUrl }}setup/inventroyadjust.aspx"><button mat-menu-item>Adjust Inventory</button></a>
					<a href="{{ legacyVergentBaseUrl }}tools/istream.aspx"><button mat-menu-item>iStream Batches</button></a>
					<a href="{{ legacyVergentBaseUrl }}tools/company_msg.aspx"><button mat-menu-item>Company Message</button></a>
					<a href="{{ legacyVergentBaseUrl }}tools/hsmngr.aspx"><button mat-menu-item>Homescreen Manager</button></a>
					<a href="{{ legacyVergentBaseUrl }}tools/reqfield_mgr.aspx"><button mat-menu-item>Required Fields</button></a>
					<a href="{{ legacyVergentBaseUrl }}tools/report_mgr.aspx"><button mat-menu-item>Report Manager</button></a>
					<a href="{{ legacyVergentBaseUrl }}tools/content_mgr.aspx"><button mat-menu-item>Content Manager</button></a>
					<a href="{{ legacyVergentBaseUrl }}tools/stmts.aspx?a=dl"><button mat-menu-item>LOC Statements (v1)</button></a>
					<a href="{{ legacyVergentBaseUrl }}tools/stmtsv2.aspx?a=dl"><button mat-menu-item>LOC Statements (v2)</button></a>
					<a href="{{ legacyVergentBaseUrl }}tools/marketing_campaign.aspx"><button mat-menu-item>Marketing Campaigns</button></a>
					<a href="{{ legacyVergentBaseUrl }}tools/comm.aspx"><button mat-menu-item>Communication Manager</button></a>
					<a href="{{ legacyVergentBaseUrl }}tools/metro2.aspx"><button mat-menu-item>Metro2 Files</button></a>
					<a href="{{ legacyVergentBaseUrl }}tools/transdata_export.aspx"><button mat-menu-item>Transaction Data Export</button></a>
					<a href="{{ legacyVergentBaseUrl }}tools/receiptreprint.aspx"><button mat-menu-item>Print Manager</button></a>
					<a href="{{ legacyVergentBaseUrl }}tools/void.aspx"><button mat-menu-item>Void Transaction</button></a>
					<a href="{{ legacyVergentBaseUrl }}tools/help.aspx"><button mat-menu-item>Help</button></a>
				</div>
			</div>
		</mat-menu>

		<button mat-button [matMenuTriggerFor]="externallinks" *ngIf="tmxAcvEnabled">
			<span>External Links</span>
			<mat-icon>arrow_drop_down</mat-icon>
		</button>
		<mat-menu #externallinks="matMenu">
			<a href="{{ tmxAcvRootUrl }}spring/acv?newSession=true&token={{ authToken }}&storeId={{ storeId }}&isOnboardingStore=Y" target="_blank"><button mat-menu-item>ACV</button></a>
			<a href="{{ tmxVirtualStoreRootUrl }}?newSession=true&token={{ authToken }}&storeId={{ storeId }}#/worklog" target="_blank"><button mat-menu-item>Virtual Store</button></a>
			<a href="{{ tmxEsignUrl }}storeNumber={{ storeId }}" target="_blank"><button mat-menu-item>ACV eSign</button></a>
			<a href="{{ eCashAcvUIUrl }}ob/store_login" target="_blank"><button mat-menu-item>ACV New Application</button></a>
		</mat-menu> -->
	</div>
</div>
