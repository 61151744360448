import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';

// utilities
import { environment } from '../../../../environments/environment';
import { SessionStorageHelper } from '../../utility/helper/sessionstorage.helper';

@Component
(
	{
		selector: 'app-top-menu',
		templateUrl: './top-menu.component.html',
		styleUrls: ['./top-menu.component.scss'],
		encapsulation: ViewEncapsulation.None
	}
)

export class TopMenuComponent
{
	public settings: Settings;

	public authToken = this.sessionStorageHelper.authTokenGet();
	public storeId = this.sessionStorageHelper.storeId();

	public legacyVergentBaseUrl = environment.LegacyVergentBaseUrl;
	public tmxAcvEnabled = environment.TMX_ACVEnabled;
	public tmxAcvRootUrl = environment.TMX_ACVRootUrl;
	public tmxEsignUrl = environment.TMX_ESignUrl;
	public tmxVirtualStoreRootUrl = environment.TMX_VirtualStoreRootUrl;
	public eCashAcvUIUrl = environment.eCash_AcvUIUrl;

	public name: string = '';
	public caseNumber: string = '';
	public phone: string = '';
	public loanId: string = '';
	public customerIdNumber: string = '';
	public vin: string = '';

	constructor(private appSettings: AppSettings, private router: Router, private sessionStorageHelper: SessionStorageHelper) { this.settings = this.appSettings.settings; }

	public redirectToSearch()
	{
		const searchQuery: string = 's=1&n=' + this.name + '&p=' + this.phone + '&lid=' + this.loanId + '&cid=' + this.customerIdNumber + '&vin=' + this.vin + '&csn='
			+ this.caseNumber + '&src=nav';

		window.open(this.legacyVergentBaseUrl + searchQuery, '_self');
	}
}
