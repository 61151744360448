import { Component, OnInit, ViewChild, HostListener, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AppSettings } from '../../app.settings';
import { Settings } from '../../app.settings.model';

// utilities
import { environment } from '../../../environments/environment';
import { SessionStorageHelper } from '../../shared/utility/helper/sessionstorage.helper';

@Component
(
	{
		selector: 'app-repolog-masterpage',
		templateUrl: './pages.component.html',
		styleUrls: ['./pages.component.scss']
	}
)

export class RepologMasterPagesComponent implements OnInit, AfterViewInit
{
	@ViewChild('sidenav') sidenav: any;
	public settings: Settings;
	public menus = ['vertical', 'horizontal'];
	public menuOption: string;
	public menuTypes = ['default', 'compact', 'mini'];
	public menuTypeOption: string;
	public isExpandedMenu: boolean = false;
	public isStickyMenu: boolean = true;
	public lastScrollTop: number = 0;
	public showBackToTop: boolean = false;
	public toggleSearchBar: boolean = false;
	public scrolledContent: any;

	public systemDate: string;
	public businessDate: string;
	public authToken = this.sessionStorageHelper.authTokenGet();
	public storeId = this.sessionStorageHelper.storeId();

	public legacyVergentBaseUrl = environment.LegacyVergentBaseUrl;
	public tmxAcvEnabled = environment.TMX_ACVEnabled;
	public tmxAcvRootUrl = environment.TMX_ACVRootUrl;
	public tmxEsignUrl = environment.TMX_ESignUrl;
	public tmxVirtualStoreRootUrl = environment.TMX_VirtualStoreRootUrl;
	public eCashAcvUIUrl = environment.eCash_AcvUIUrl;
	public apiToken = this.sessionStorageHelper.apiTokenGet();

	private defaultMenu: string; // declared for return default menu when window resized

	constructor
	(
		private appSettings: AppSettings,
		private router: Router,
		private sessionStorageHelper: SessionStorageHelper
		)
	{
		this.settings = this.appSettings.settings;

		const today = new Date();
		const date = (today.getMonth() + 1) + '/' + today.getDate() + '/' + today.getFullYear();
		const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();

		this.systemDate = date + ' ' + time;
		this.businessDate = date + ' ' + time;
	}

	ngOnInit()
	{
		if (window.innerWidth <= 768)
		{
			this.settings.menu = 'vertical';
			this.settings.sidenavIsOpened = false;
			this.settings.sidenavIsPinned = false;
		}

		this.menuOption = this.settings.menu;
		this.menuTypeOption = this.settings.menuType;
		this.defaultMenu = this.settings.menu;
	}

	ngAfterViewInit()
	{
		setTimeout
		(
			() =>
			{
				this.settings.loadingSpinner = false;
			}, 300
		);

		this.router.events.subscribe
		(
			event =>
			{
				if (event instanceof NavigationEnd)
				{
					if (!this.settings.sidenavIsPinned)
					{
						this.sidenav.close();
					}

					if (window.innerWidth <= 768)
					{
						this.sidenav.close();
					}
				}
			}
		);
	}

	public toggleSidenav()
	{
		this.sidenav.toggle();
	}

	public onPsScrollY(event)
	{
		this.scrolledContent = event.target;
		(this.scrolledContent.scrollTop > 300) ? this.showBackToTop = true : this.showBackToTop = false;

		if (this.settings.menu === 'horizontal')
		{
			if (this.settings.fixedHeader)
			{
				const currentScrollTop = (this.scrolledContent.scrollTop > 56) ? this.scrolledContent.scrollTop : 0;
				(currentScrollTop > this.lastScrollTop) ? this.isStickyMenu = true : this.isStickyMenu = false;
				this.lastScrollTop = currentScrollTop;
			}
			else
			{
				(this.scrolledContent.scrollTop > 56) ? this.isStickyMenu = true : this.isStickyMenu = false;
			}
		}
	}

	public scrollToTop()
	{
		const scrollDuration = 200;
		const scrollStep = -this.scrolledContent.scrollTop / (scrollDuration / 20);
		const scrollInterval = setInterval
		(
			() =>
			{
				if (this.scrolledContent.scrollTop !== 0)
				{
					this.scrolledContent.scrollBy(0, scrollStep);
				}
				else
				{
					clearInterval(scrollInterval);
				}
			}
			, 10
		);

		if (window.innerWidth <= 768)
		{
			this.scrolledContent.scrollTop = 0;
		}
	}

	@HostListener('window:resize')
	public onWindowResize(): void
	{
		if (window.innerWidth <= 768)
		{
			this.settings.sidenavIsOpened = false;
			this.settings.sidenavIsPinned = false;
			this.settings.menu = 'vertical';
		}
		else
		{
			(this.defaultMenu === 'horizontal') ? this.settings.menu = 'horizontal' : this.settings.menu = 'vertical';
			this.settings.sidenavIsOpened = true;
			this.settings.sidenavIsPinned = true;
		}
	}

	public closeSubMenus()
	{
		const menu = document.querySelector('.sidenav-menu-outer');

		if (menu)
		{
			// TODO - fix this logic
			// tslint:disable-next-line:prefer-for-of
			for (let i = 0; i < menu.children[0].children.length; i++)
			{
				const child = menu.children[0].children[i];
				if (child)
				{
					if (child.children[0].classList.contains('expanded'))
					{
						child.children[0].classList.remove('expanded');
						child.children[1].classList.remove('show');
					}
				}
			}
		}
	}

	onEnter(searchTerm: string)
	{
		this.router.navigate(['/repolog/search', searchTerm]);
	}
}
