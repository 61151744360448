import { throwError as observableThrowError, throwError } from 'rxjs';
import { HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { SessionStorageHelper } from 'app/shared/utility/helper/sessionstorage.helper';
import { HeaderHelper } from 'app/shared/utility/helper/header.helper';

// helper
export abstract class BaseService
{
	constructor
	(
		protected sessionStorageHelper: SessionStorageHelper,
		protected headerHelper: HeaderHelper
	)
	{
	}

	public createAuthHeader(authRequired: boolean): HttpHeaders
	{
		let httpHeaders: HttpHeaders = new HttpHeaders();

		if (authRequired)
		{
			httpHeaders = httpHeaders.append('Authorization', `Bearer ${this.sessionStorageHelper.authTokenGet()}`);
		}

		return httpHeaders;
	}

	protected handleError(error: HttpErrorResponse)
	{
		if (error.error instanceof ErrorEvent)
		{
			// A client-side or network error occurred. Handle it accordingly.
			console.error('An error occurred:', error.error.message);
		}
		else
		{
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong,
			console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
		}

		// return an observable with a user-facing error message
		return throwError('Something bad happened. Please try again later.');
	}
}
