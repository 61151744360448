import { Routes } from '@angular/router';
import { RepologMasterPagesComponent } from './repolog/pages/pages.component';
import { NotFoundComponent } from './shared/pages/errors/not-found.component';
import { ErrorComponent } from './shared/pages/errors/error.component';

// Helper
import { AuthGuard } from './shared/utility/helper/auth-guard.helper';

export const routes: Routes =
[
	{
		path: 'repolog',
		component: RepologMasterPagesComponent,
		loadChildren: () => import('./repolog/repolog.module').then(m => m.RepologModule),
		data:
		{
			breadcrumb: 'RepoLog'
		},
		canActivate: [AuthGuard]
	},
	{
		path: 'login',
		loadChildren: () => import('./shared/pages/login/login.module').then(m => m.LoginModule)
	},
	{
		path: '',
		redirectTo: '/login',
		pathMatch: 'full'
	},
	{
		path: 'error',
		component: ErrorComponent,
		data:
		{
			breadcrumb: 'Error'
		}
	},
	{
		path: '**',
		component: NotFoundComponent
	}
];
