import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';

// helper
import { AuthGuard } from './shared/utility/helper/auth-guard.helper';

import { SharedModule } from './shared/shared.module';
import { routes } from './app.routing';

import { AppComponent } from './app.component';
import { PagesComponent } from './shared/pages/pages.component';
import { RepologMasterPagesComponent } from './repolog/pages/pages.component';
import { TopMenuComponent } from './shared/components/top-menu/top-menu.component';

import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AppSettings } from './app.settings';

// service main
import { LoginService } from './shared/pages/login/login.service';

// Not Found / Error
import { ErrorComponent } from './shared/pages/errors/error.component';
import { NotFoundComponent } from './shared/pages/errors/not-found.component';
import { SessionStorageHelper } from './shared/utility/helper/sessionstorage.helper';
import { RepoLogFilterSessionStorageHelper } from './shared/utility/helper/repolog-filter-session-storage.helper';
import { HeaderHelper } from './shared/utility/helper/header.helper';
import { RouterModule } from '@angular/router';

@NgModule
(
	{
		declarations:
		[
			AppComponent,
			PagesComponent,
			RepologMasterPagesComponent,
			TopMenuComponent,
			ErrorComponent,
			NotFoundComponent
		],
		imports:
		[
			BrowserModule,
			BrowserAnimationsModule,
			FormsModule,
			ReactiveFormsModule,
			HttpClientModule,
			SharedModule,
			RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
			ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
		],
		providers:
		[
			AppSettings,
			OverlayContainer,

			// helper providers
			AuthGuard,
			SessionStorageHelper,
			RepoLogFilterSessionStorageHelper,
			HeaderHelper,
			LoginService
		],
		bootstrap:
		[
			AppComponent
		]
	}
)

export class AppModule { }
