import { Injectable } from '@angular/core';

@Injectable
(
	{
		providedIn: 'root'
	}
)
export class RepoLogFilterSessionStorageHelper
{
	//////////////////////////////////////////////// State Session Storage ////////////////////////////////////////////// 
	repoLogQueueStateIdGet(): number[]
	{
		const selectedStateIds: number[] = [];
		const repoLogQueueStateId = sessionStorage.getItem('repoLogQueueStateId');
		
		if(repoLogQueueStateId === null)
		{
			return null;
		}

		const stringArray = repoLogQueueStateId !== '' ? repoLogQueueStateId.split(',') : [];

		stringArray.forEach(element => {
			selectedStateIds.push(Number(element));
		});

		return selectedStateIds;
	}

	repoLogQueueStateIdRemove()
	{
		sessionStorage.removeItem('repoLogQueueStateId');
	}

	repoLogQueueStateIdSet(value: string)
	{
		sessionStorage.setItem('repoLogQueueStateId', value);
	}

	//////////////////////////////////////////////// Region Session Storage ////////////////////////////////////////////// 
	repoLogQueueRegionIdsGet(): number[]
	{
		const selectedRegionIds: number[] = [];

		const repoLogQueueRegionIds = sessionStorage.getItem('repoLogQueueRegionIds');
		
		if(repoLogQueueRegionIds === null)
		{
			return null;
		}
		
		const stringArray = repoLogQueueRegionIds !== null && repoLogQueueRegionIds !== '' ? repoLogQueueRegionIds.split(',') : [];

		stringArray.forEach(element => {
			selectedRegionIds.push(Number(element));
		});

		return selectedRegionIds;
	}

	repoLogQueueRegionIdsSet(value: string)
	{
		sessionStorage.setItem('repoLogQueueRegionIds', value);
	}

	repoLogQueueRegionIdsRemove() 
	{
		sessionStorage.removeItem('repoLogQueueRegionIds');
	}

	//////////////////////////////////////////////// District Session Storage ////////////////////////////////////////////// 
	repoLogQueueDistrictIdsGet(): number[]
	{
		const selectedDistrictIds: number[] = [];

		const repoLogQueueDistrictIds = sessionStorage.getItem('repoLogQueueDistrictIds');

		if(repoLogQueueDistrictIds === null)
		{
			return null;
		}

		const stringArray = repoLogQueueDistrictIds !== null && repoLogQueueDistrictIds !== '' ? repoLogQueueDistrictIds.split(',') : [];

		stringArray.forEach(element => {
			selectedDistrictIds.push(Number(element));
		});

		return selectedDistrictIds;
	}

	repoLogQueueDistrictIdsSet(value: string)
	{
		sessionStorage.setItem('repoLogQueueDistrictIds', value);
	}
	
	repoLogQueueDistrictIdsRemove()
	{
		sessionStorage.removeItem('repoLogQueueDistrictIds');
	}

	//////////////////////////////////////////////// Store Session Storage ////////////////////////////////////////////// 
	repoLogQueueStoreIdsGet(): number[]
	{
		const selectedStoreIds: number[] = [];
		const repoLogDeskUserQueueStoreIds = sessionStorage.getItem('repoLogQueueStoreIds');
		const stringArray = repoLogDeskUserQueueStoreIds !== null && repoLogDeskUserQueueStoreIds !== '' ? repoLogDeskUserQueueStoreIds.split(',') : [];

		stringArray.forEach(element => {
			selectedStoreIds.push(Number(element));
		});

		return selectedStoreIds;
	}

	repoLogQueueStoreIdsSet(value: string)
	{
		sessionStorage.setItem('repoLogQueueStoreIds', value);
	}

	repoLogQueueStoreIdsRemove() 
	{
		sessionStorage.removeItem('repoLogQueueStoreIds');
	}

	// ////////////////////////////////////////////// Start Date Filter ////////////////////////////////////////////// 
	repoLogQueueStartDateRemove()
	{
		sessionStorage.removeItem('repoLogQueueStartDate');
	}

	repoLogQueueStartDateGet(): string
	{
		const repoLogQueueStartDate = sessionStorage.getItem('repoLogQueueStartDate');
		return repoLogQueueStartDate;
	}

	repoLogQueueStartDateSet(value: string)
	{
		sessionStorage.setItem('repoLogQueueStartDate', value);
	}

	// ////////////////////////////////////////////// End Date Filter ////////////////////////////////////////////// 
	repoLogQueueEndDateRemove()
	{
		sessionStorage.removeItem('repoLogQueueEndDate');
	}

	repoLogQueueEndDateGet(): string
	{
		const repoLogQueueEndDate = sessionStorage.getItem('repoLogQueueEndDate');
		return repoLogQueueEndDate;
	}

	repoLogQueueEndDateSet(value: string)
	{
		sessionStorage.setItem('repoLogQueueEndDate', value);
	}
}