import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// utilities
import { environment } from '../../../../environments/environment';
import { BaseService } from 'app/shared/services/base.service';
import { SessionStorageHelper } from 'app/shared/utility/helper/sessionstorage.helper';
import { HeaderHelper } from 'app/shared/utility/helper/header.helper';

@Injectable
(
	{
		providedIn: 'root'
	}
)
export class AuthTokenService extends BaseService
{
	private httpHeaders: HttpHeaders;

	constructor
	(
		private httpClient: HttpClient,
		protected sessionStorageHelper: SessionStorageHelper,
		protected headerHelper: HeaderHelper
	)
	{
		super
		(
			sessionStorageHelper,
			headerHelper
		);

		this.httpHeaders = this.createAuthHeader(false);
	}

	getUserJwt(userJwtGuid: string): Observable<string>
	{
		const url = `${environment.apiBaseUrl}api/authenticate/${userJwtGuid}/jwt`;

		return this.httpClient.get<string>
		(
			url,
			{
				headers: this.headerHelper.addApplicationJsonHeader(this.httpHeaders)
			}
		);
	}
}
