<div  class="page-outer-container">
<div>
	<mat-toolbar color="primary" class="flex-p-x" class="top-bar">
		<div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
			<div fxLayout="row" fxLayoutAlign="start center"> 
				<a href="{{legacyVergentBaseUrl}}/default.aspx?s=api&token={{apiToken}}" style="cursor: hand;" target="_blank">
					<img src="/assets/images/logo-tlxe.png" alt="Vergent LMS" class="logo" /> 
				</a>
			</div>
			<div fxLayout="row" fxLayoutAlign="end center">
				<div fxLayout="row" fxLayoutAlign="start center" fxShow="false" fxShow.gt-xs class="search-bar">
					<form method="get">
						<input type="text" #searchBox placeholder="Type to search..." class="mat-elevation-z3"  [class.show]="toggleSearchBar" (keyup.enter)="onEnter(searchBox.value)">
						<button mat-icon-button (click)="toggleSearchBar = !toggleSearchBar" type="button">
							<mat-icon>search</mat-icon>
						</button>
					</form>
				</div>
			</div>
		</div>
	</mat-toolbar>
</div>

<mat-sidenav-container autosize>
	<mat-sidenav #sidenav mode="side" opened="true">
		<mat-nav-list style="height: 70%">
			<mat-list-item>
				<button mat-icon-button (click)="isExpandedMenu = !isExpandedMenu">
					<mat-icon *ngIf="!isExpandedMenu">chevron_right</mat-icon>
					<mat-icon *ngIf="isExpandedMenu">chevron_left</mat-icon>
				</button>
			</mat-list-item>

			<hr/>

			<a [routerLink]="['/repolog/queue']">
				<mat-list-item>
					<mat-icon mat-list-icon>directions_car</mat-icon>
					<p matLine *ngIf="isExpandedMenu">RepoLog</p>
				</mat-list-item>
			</a>
			
		</mat-nav-list>

		<mat-nav-list>
			<hr/>
			
		</mat-nav-list>
	</mat-sidenav>

	<mat-sidenav-content (psScrollY)="onPsScrollY($event)">
		<div id="main-content" class="transition-2" perfectScrollbar (psScrollY)="onPsScrollY($event)" [class.horizontal-menu-hidden]="isStickyMenu" style="height:100%">
			<app-top-menu></app-top-menu>
			<router-outlet></router-outlet>
		</div>

		<div *ngIf="showBackToTop" fxLayout="row" fxLayoutAlign="center center" class="back-to-top transition-2" (click)="scrollToTop();">
			<mat-icon>arrow_upward</mat-icon>
		</div>
	</mat-sidenav-content>
</mat-sidenav-container>
</div>

<div style="position:absolute; bottom:0px; height:35px; left:0px; right:0px; overflow:hidden;">
	<mat-toolbar fxLayout="column" color="primary" class="flex-p-x" class="footer bottom-bar">
		<div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
			<div fxLayout="row" fxLayoutAlign="start center"><span>System Date: {{ systemDate }} &nbsp; &nbsp; &nbsp; &nbsp; Business Date: {{ businessDate }}</span> </div>
			<div fxLayout="row" fxLayoutAlign="center center"> <span>Powered By Vergent</span> </div>
			<div fxLayout="row" fxLayoutAlign="end center"> <span>ecashlms.prod-20190516&nbsp; &nbsp; &nbsp; &nbsp;Contact Us</span> </div>
		</div>
	</mat-toolbar>
</div>
