import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { first } from 'rxjs/operators';

// utilities
import { environment } from '../../../../environments/environment';
import { AuthTokenService } from '../../components/auth/auth-token.service';
import { SessionStorageHelper } from './sessionstorage.helper';
import { TitleLogRole } from './enum-titlelog-role';

@Injectable
(
	{
		providedIn: 'root'
	}
)
export class AuthGuard implements CanActivate
{
	private userRole: number;
	private requestedRoute: string;
	private requestedReferer: string;

	constructor
	(
		private router: Router,
		private sessionStorageHelper: SessionStorageHelper,
		private authTokenService: AuthTokenService
	)
	{
	}

	canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot): boolean
	{
		const userJwtId: string = activatedRouteSnapshot.queryParamMap.get('apiValidate');

		const appBaseUrl = environment.appBaseUrl;
		const tlxeBaseUrl = environment.tlxeBaseUrl;
		this.requestedRoute = routerStateSnapshot.url;
		this.requestedReferer = activatedRouteSnapshot.queryParamMap.get('referer');

		if (this.requestedRoute === 'queue')
		{

			this.requestedRoute = (this.getRolePath() + this.requestedRoute);
		}

		if (this.requestedReferer != null && this.requestedReferer !== '')
		{
			this.sessionStorageHelper.refererSet(this.requestedReferer);
		}

		let isAuthorized: boolean = this.isLoggedIn();
		const isTokenExpired: boolean = this.isTokenExpired();

		if (userJwtId != null)
		{
			this.getUserJwt(userJwtId);
		}
		else
		{
			if (!isAuthorized || isTokenExpired)
			{
				isAuthorized = false;
				this.sessionStorageHelper.authTokenRemove();

				window.location.href = tlxeBaseUrl + '/logon.aspx?ReturnUrl=' + appBaseUrl + '/' + this.requestedRoute + '&apiValidate=0';
			}
		}

		return isAuthorized;
	}

	public isLoggedIn(): boolean
	{
		const hasAuthCode = this.sessionStorageHelper.authTokenGet();
		return hasAuthCode === '' ? false : true;
	}

	public isTokenExpired(): boolean
	{
		const expiration = this.sessionStorageHelper.expirationGet();

		if (expiration === 0)
		{
			return true;
		}

		const now = new Date().valueOf();
		const expired = (now > expiration);

		return expired;
	}

	public getUserJwt(userJwtId: string)
	{
		this.authTokenService
			.getUserJwt(userJwtId)
			.pipe(first())
			.subscribe
			(
				{
					next: results =>
					{
						const authToken = JSON.parse(results);
						this.sessionStorageHelper.authTokenSet(authToken);
						this.router.navigateByUrl('/' + this.requestedRoute);
					},
					error: () => console.error('Something went wrong')
				}
			);
	}

	getRolePath(): string
	{
		this.userRole = + this.sessionStorageHelper.titleLogRoleGet();
		let path: string;

		switch (this.userRole)
		{
			case TitleLogRole.StoreUser:
				path = 'titlelog/storeuser/';
				break;
			case TitleLogRole.Manager:
				path = 'titlelog/manager/';
				break;
			case TitleLogRole.CorpUser:
				path = 'titlelog/corporate/';
				break;
			case TitleLogRole.DeskUser:
				path = 'titlelog/deskuser/';
				break;
			case TitleLogRole.RegionalManager:
				path = 'titlelog/regionalmanager';
				break;
			default:
				path = 'titlelog';
				break;
		}
		return path;
	}

	onCompletedService$(onCompletedService$: any): any {
		throw new Error('Method not implemented.');
	}
}
