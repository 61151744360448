export const environment =
{
	production: true,

	apiBaseUrl: 'https://dev.repolog.api.vergentlms.com/',
	appBaseUrl: 'https://dev.repolog.app.vergentlms.com/',
	LegacyVergentBaseUrl: 'https://ttlmaxqa3.vergentlms.com',

	// ACV Settings
	TMX_ACVEnabled: false,
	TMX_ACVRootUrl: 'http://ecashqacoreacv.titlemax.biz:8080/ACVApp',
	TMX_ESignUrl: 'https://ecashpaperless-qa.titlemax.com/esign-service-web-ecash/#/session/list?',
	TMX_VirtualStoreRootUrl: 'http://ecashqatlxmicrosvc03.titlemax.biz:8099',
	eCash_AcvUIUrl: 'http://localhost/acv.webui',

	// TLXe Settings
	tlxeBaseUrl: 'https://ttlmaxqa3.vergentlms.com',
	acvBaseUrl: 'https://acvqa.vergentlms.com/ui/acv/'
};
