import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

// models
import { AuthenticationRequestModel } from './login.model';

// utilities
import { environment } from '../../../../environments/environment';
import { SessionStorageHelper } from '../../utility/helper/sessionstorage.helper';
import { BaseService } from 'app/shared/services/base.service';
import { HeaderHelper } from 'app/shared/utility/helper/header.helper';

@Injectable
(
	{
		providedIn: 'root'
	}
)

export class LoginService extends BaseService
{
	private httpHeaders: HttpHeaders;

	constructor
	(
		private httpClient: HttpClient,
		protected sessionStorageHelper: SessionStorageHelper,
		protected headerHelper: HeaderHelper,
		public router: Router
	)
	{
		super
		(
			sessionStorageHelper,
			headerHelper
		);

		this.httpHeaders = this.createAuthHeader(true);
	}

	public login(login: AuthenticationRequestModel): Observable<string>
	{
		const url = `${environment.apiBaseUrl}api/authenticate`;

		return this.httpClient.post<string>
		(
			url,
			JSON.stringify(login),
			{
				headers: this.headerHelper.addApplicationJsonHeader(this.httpHeaders)
			}
		);
	}

	public logOff()
	{
		localStorage.clear();

		this.router.navigate(['/login']);
	}

	public isLoggedIn(): boolean
	{
		const hasAuthCode = this.sessionStorageHelper.authTokenGet();

		if (hasAuthCode === '')
		{
			return false;
		}

		return true;
	}
}
